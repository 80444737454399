function PasswordRule(icon, verifyFunction) {
  this.verifyFunction = verifyFunction;
  this.icon = icon;
}

function regexVerifyFunction(regex) {
  return function (string) {
    return regex.test(string);
  };
}

let lengthRule = new PasswordRule("password_length_check_icon", regexVerifyFunction(/^.{12,}$/));
let uppercaseRule = new PasswordRule("password_uppercase_check_icon", regexVerifyFunction(/[A-Z]/));
let lowercaseRule = new PasswordRule("password_lowercase_check_icon", regexVerifyFunction(/[a-z]/));
let numericRule = new PasswordRule("password_numeric_check_icon", regexVerifyFunction(/[0-9]/));
let specialRule = new PasswordRule("password_special_check_icon", regexVerifyFunction(/[!-/:-@[-`{-~]/));
let matchRule = new PasswordRule("password_match_check_icon", function (password) {
  return password === document.getElementById("developer_password_confirmation").value;
});

let passwordRules = [lengthRule, uppercaseRule, lowercaseRule, numericRule, specialRule];
let passwordConfirmationRules = [matchRule];

let ruleFailedIconClasses = ["far", "fa-check-circle"];
let ruleFailedIconColor = "#a8b3b8";
let confirmationRuleFailedIconClasses = ["far", "fa-check-circle"];
let confirmationRuleFailedIconColor = "#a8b3b8";
let ruleSucceededIconClasses = ["fas", "fa-check-circle"];
let ruleSucceededIconColor = "#008760";

let updatePasswordRuleIcons = function () {
  let password = document.getElementById("developer_password").value;
  passwordRules.forEach((rule) => {
    let icon = document.getElementById(rule.icon);

    if (icon) {
      if (rule.verifyFunction(password)) {
        ruleFailedIconClasses.forEach((className) => {
          icon.classList.remove(className);
        });
        ruleSucceededIconClasses.forEach((className) => {
          icon.classList.add(className);
        });
        icon.style.color = ruleSucceededIconColor;
      } else {
        ruleSucceededIconClasses.forEach((className) => {
          icon.classList.remove(className);
        });
        ruleFailedIconClasses.forEach((className) => {
          icon.classList.add(className);
        });
        icon.style.color = ruleFailedIconColor;
      }
    }
  });

  passwordConfirmationRules.forEach((rule) => {
    let icon = document.getElementById(rule.icon);

    if (icon) {
      if (rule.verifyFunction(password)) {
        confirmationRuleFailedIconClasses.forEach((className) => {
          icon.classList.remove(className);
        });
        ruleSucceededIconClasses.forEach((className) => {
          icon.classList.add(className);
        });
        icon.style.color = ruleSucceededIconColor;
      } else {
        ruleSucceededIconClasses.forEach((className) => {
          icon.classList.remove(className);
        });
        confirmationRuleFailedIconClasses.forEach((className) => {
          icon.classList.add(className);
        });
        icon.style.color = confirmationRuleFailedIconColor;
      }
    }
  });
};

let showPasswordTooltip = function () {
  const tooltipContainer = document.getElementsByClassName("password-tooltip-container")[0];
  if (tooltipContainer) {
    tooltipContainer.style.visibility = "visible";
  }

  const tooltipText = document.getElementsByClassName("righttooltiptext")[0];
  if (tooltipText) {
    tooltipText.style.visibility = "visible";
  }
};

let hidePasswordTooltip = function () {
  const tooltipContainer = document.getElementsByClassName("password-tooltip-container")[0];
  if (tooltipContainer) {
    tooltipContainer.style.visibility = "hidden";
  }

  const tooltipText = document.getElementsByClassName("righttooltiptext")[0];
  if (tooltipText) {
    tooltipText.style.visibility = "hidden";
  }
};

let toggleTooltipErrorIcon = function () {
  passwordRules.forEach((rule) => {
    let icon = document.getElementById(rule.icon);

    if (icon) {
      icon.classList.remove("far");
    }
  });
  ruleFailedIconClasses = ["fas", "fa-times-circle"];
  ruleFailedIconColor = "#D32F4C";
  updatePasswordRuleIcons();
};

let allRulesPass = function () {
  let isValid = true;
  let password = document.getElementById("developer_password").value;
  passwordRules.forEach((rule) => {
    if (!rule.verifyFunction(password)) {
      isValid = false;
    }
  });
  passwordConfirmationRules.forEach((rule) => {
    if (!rule.verifyFunction(password)) {
      isValid = false;
    }
  });

  return isValid;
};

let toggleConfirmationTooltipErrorIcon = function () {
  passwordConfirmationRules.forEach((rule) => {
    let icon = document.getElementById(rule.icon);
    if (icon) {
      icon.classList.remove("far");
    }
  });
  confirmationRuleFailedIconClasses = ["fas", "fa-times-circle"];
  confirmationRuleFailedIconColor = "#D32F4C";
  updatePasswordRuleIcons();
};

let hidePasswordTooltipIfNoErrors = function () {
  let isValid = allRulesPass();
  if (isValid) {
    hidePasswordTooltip();
  } else {
    showPasswordTooltip();
  }
};

let showPassword = false;
let showConfirmPassword = false;

let setPasswordFieldVisibility = function (isVisible, passwordField, icon) {
  if (!icon) {
    return;
  }

  if (isVisible) {
    passwordField.setAttribute("type", "text");
    icon.classList.remove("fa-eye-slash");
    icon.classList.add("fa-eye");
  } else {
    passwordField.setAttribute("type", "password");
    icon.classList.remove("fa-eye");
    icon.classList.add("fa-eye-slash");
  }
};

document.addEventListener("DOMContentLoaded", function () {
  let activationForm = document.getElementById("activation-form");

  if (activationForm) {
    activationForm.addEventListener("submit", function (event) {
      let isValid = allRulesPass();
      const acceptTosCheckbox = document.getElementById("acknowledged_privacy_policy_and_tos");

      if (acceptTosCheckbox && !acceptTosCheckbox.checked) {
        isValid = false;
      }

      if (!isValid) {
        event.preventDefault();
      }
    });

    ["keyup", "change", "paste"].forEach((eventName) => {
      activationForm.addEventListener(eventName, function () {
        let isValid = allRulesPass();
        let acceptTosCheckbox = document.getElementById("acknowledged_privacy_policy_and_tos");

        if (!acceptTosCheckbox) {
          acceptTosCheckbox = document.getElementById("acknowledged_privacy_policy");
        }

        const activateButton = document.getElementById("activate-button");

        if (acceptTosCheckbox && !acceptTosCheckbox.checked) {
          isValid = false;
        }

        if (activateButton) {
          activateButton.disabled = !isValid;
        }
      });
    });
  }

  const resetPasswordForm = document.getElementById("reset-password-form");
  const passwordRulesTooltip = document.getElementById("password_rules_tooltip");
  // check for password rules tooltip presence so new rules are only enabled for new form
  if (resetPasswordForm && passwordRulesTooltip) {
    resetPasswordForm.addEventListener("submit", function (event) {
      let isValid = allRulesPass();
      if (!isValid) {
        event.preventDefault();
      }
    });

    ["keyup", "change", "paste"].forEach((eventName) => {
      resetPasswordForm.addEventListener(eventName, function () {
        let isValid = allRulesPass();

        const resetPasswordButton = document.getElementById("reset-password-button");

        if (resetPasswordButton) {
          resetPasswordButton.disabled = !isValid;
        }
      });
    });
  }

  if (activationForm || resetPasswordForm) {
    const passwordToggle = document.getElementById("password-toggle");

    if (passwordToggle) {
      passwordToggle.addEventListener("click", function () {
        showPassword = !showPassword;
        setPasswordFieldVisibility(
          showPassword,
          document.getElementById("developer_password"),
          document.getElementById("password-toggle")
        );
      });
    }

    const confirmPasswordToggle = document.getElementById("confirm-password-toggle");

    if (confirmPasswordToggle) {
      confirmPasswordToggle.addEventListener("click", function () {
        showConfirmPassword = !showConfirmPassword;
        setPasswordFieldVisibility(
          showConfirmPassword,
          document.getElementById("developer_password_confirmation"),
          document.getElementById("confirm-password-toggle")
        );
      });
    }

    const developerPassword = document.getElementById("developer_password");

    if (developerPassword) {
      developerPassword.addEventListener("input", updatePasswordRuleIcons);
      developerPassword.addEventListener("focus", showPasswordTooltip);
      developerPassword.addEventListener("focusout", toggleTooltipErrorIcon);
      developerPassword.addEventListener("focusout", hidePasswordTooltipIfNoErrors);
    }

    const developerPasswordConfirmation = document.getElementById("developer_password_confirmation");

    if (developerPasswordConfirmation) {
      developerPasswordConfirmation.addEventListener("input", updatePasswordRuleIcons);
      developerPasswordConfirmation.addEventListener("focus", showPasswordTooltip);
      developerPasswordConfirmation.addEventListener("focusout", toggleConfirmationTooltipErrorIcon);
      developerPasswordConfirmation.addEventListener("focusout", hidePasswordTooltipIfNoErrors);
    }
  }
});
