/**
 * File that gets included in devise.html.erb and sets up some stuff on the
 * auth page.
 */

import { I18n } from "@lib/i18n"; // eslint-disable-line local-eslint-rules/no-ko-i18n-in-react

const { t } = I18n.namespace("ab-parsley-addons");

// Focus on the first text input type on the form so it's easy to start typing.
// First try a "text" input box, if you can't find it, find an "email" text box.
const firstInput =
  document.querySelector("input[type=text]") ||
  document.querySelector("input[type=email]") ||
  document.querySelector("input[type=password]");

if (firstInput) {
  firstInput.focus();
}

const emailRegex = /^[^@]+@[^@]+$/;

const errorListCSS = `
  .error-list-no-error {
    color: #ff5b68;
    font-weight: 400;
    font-family: Aribau, helvetica, arial, sans-serif;
    font-size: 13px;
    display: none;
    padding: 0;
    margin: 0;
  }
  
  .error-list-error {
    color: #ff5b68;
    font-weight: 400;
    font-family: Aribau, helvetica, arial, sans-serif;
    font-size: 13px;
    display: inline;
    padding: 0;
    margin: 0;
  }
`;

const errorCSS = `
  .error {
    list-style-type: none;
    padding: 2px;
  }
`;

const errorListStyle = document.createElement("style");
errorListStyle.type = "text/css";
errorListStyle.appendChild(document.createTextNode(errorListCSS));
document.head.appendChild(errorListStyle);

const errorStyle = document.createElement("style");
errorStyle.type = "text/css";
errorStyle.appendChild(document.createTextNode(errorCSS));
document.head.appendChild(errorStyle);

const inputErrorId = (inputId) => {
  return `${inputId}-error`;
};

// If message is null the input is valid
const setValidity = (inputId, message) => {
  const input = document.getElementById(inputId);
  const inputError = document.getElementById(inputErrorId(inputId));

  if (!input || !inputError) {
    return;
  }

  input.classList.remove("invalid");
  inputError.classList.remove("error-list-error");
  inputError.classList.remove("error-list-no-error");

  if (!message) {
    inputError.classList.add("error-list-no-error");
  }

  if (message) {
    input.classList.add("invalid");
    inputError.classList.add("error-list-error");
    inputError.firstChild.textContent = message;
  }
};

const setupValidationForInput = (inputId, required, validationsAndMessages) => {
  const input = document.getElementById(inputId);

  if (!input) {
    return;
  }

  const inputParentNode = input.parentNode;
  const inputErrorParentNode = input.type === "checkbox" ? inputParentNode.parentNode : inputParentNode;
  const elementBeforeError = input.type === "checkbox" ? inputParentNode : input;

  const error = document.createElement("li");
  error.classList.add("error");
  const errorList = document.createElement("ul");
  errorList.id = inputErrorId(inputId);
  errorList.classList.add("error-list-no-error");
  errorList.appendChild(error);
  inputErrorParentNode.insertBefore(errorList, elementBeforeError.nextSibling);

  if (required) {
    input.setAttribute("required", required);
  }

  input.addEventListener("input", function (event) {
    const input = event.srcElement;

    if (input.classList.contains("invalid")) {
      let invalid = false;

      for (const validation of validationsAndMessages) {
        if (!validation["validation"](event.target)) {
          invalid = true;
          setValidity(inputId, validation["message"]);
          break;
        }
      }

      if (!invalid) {
        setValidity(inputId);
      }
    }
  });
};

const setupValidationForForm = (formId, inputIdsValidationsAndMessages) => {
  const form = document.getElementById(formId);
  if (!form) {
    return;
  }

  form.setAttribute("novalidate", true);

  for (const inputObject of inputIdsValidationsAndMessages) {
    setupValidationForInput(inputObject["inputId"], inputObject["required"], inputObject["validationsAndMessages"]);
  }

  form.addEventListener("submit", function (event) {
    let isValid = true;
    for (const inputObject of inputIdsValidationsAndMessages) {
      const inputId = inputObject["inputId"];
      const input = document.getElementById(inputId);

      if (!input) {
        continue;
      }

      const validationsAndMessages = inputObject["validationsAndMessages"];

      for (const validation of validationsAndMessages) {
        if (!validation["validation"](input)) {
          isValid = false;
          setValidity(inputId, validation["message"]);
          break;
        }
      }
    }

    if (!isValid) {
      event.preventDefault();
    }
  });
};

// sessions#email_collect form validation
const emailCollectFormId = "email-form";
const emailCollectFormValidations = [
  {
    inputId: "developer-email",
    required: true,
    validationsAndMessages: [
      {
        validation: (element) => element.value.length !== 0,
        message: t("field-is-required-message", "This field is required."),
      },
      {
        validation: function (element) {
          return emailRegex.test(element.value);
        },
        message: t("should-be-a-valid-email-message", "This field should be a valid email."),
      },
    ],
  },
];

setupValidationForForm(emailCollectFormId, emailCollectFormValidations);

// sessions#new form validation
const sessionsNewFormId = "developer_signin";
const sessionsNewFormValidations = [
  {
    inputId: "developer_password",
    required: true,
    validationsAndMessages: [
      {
        validation: (element) => element.value.length !== 0,
        message: t("field-is-required-message", "This field is required."),
      },
    ],
  },
];

setupValidationForForm(sessionsNewFormId, sessionsNewFormValidations);

// confirmations#activation_form validation
const confirmationsActivationFormId = "edit_developer";
const confirmationsActivationFormValidations = [
  {
    inputId: "developer_first_name",
    required: true,
    validationsAndMessages: [
      {
        validation: (element) => element.value.length !== 0,
        message: t("field-is-required-message", "This field is required."),
      },
    ],
  },
  {
    inputId: "developer_last_name",
    required: true,
    validationsAndMessages: [
      {
        validation: (element) => element.value.length !== 0,
        message: t("field-is-required-message", "This field is required."),
      },
    ],
  },
  {
    inputId: "developer_department",
    required: true,
    validationsAndMessages: [
      {
        validation: (element) => element.value.length !== 0,
        message: t("field-is-required-message", "This field is required."),
      },
    ],
  },
  {
    inputId: "developer_password",
    required: true,
    validationsAndMessages: [
      {
        validation: (element) => element.value.length !== 0,
        message: t("field-is-required-message", "This field is required."),
      },
    ],
  },
  {
    inputId: "developer_password_confirmation",
    required: true,
    validationsAndMessages: [
      {
        validation: (element) => element.value.length !== 0,
        message: t("field-is-required-message", "This field is required."),
      },
      {
        validation: (element) => element.value === document.getElementById("developer_password").value,
        message: t("field-must-match", "This field must match."),
      },
    ],
  },
  {
    inputId: "acknowledged_privacy_policy",
    required: true,
    validationsAndMessages: [
      {
        validation: (element) => element.checked,
        message: t("field-is-required-message", "This field is required."),
      },
    ],
  },
  {
    inputId: "acknowledged_privacy_policy_and_tos",
    required: true,
    validationsAndMessages: [
      {
        validation: (element) => element.checked,
        message: t("field-is-required-message", "This field is required."),
      },
    ],
  },
];

setupValidationForForm(confirmationsActivationFormId, confirmationsActivationFormValidations);

// account_settings#acknowledge form validation
const accountAcknowledgeFormId = "tos-acknowledge";
const accountAcknowledgeFormValidations = [
  {
    inputId: "confirmed_sandbox_tos",
    required: true,
    validationsAndMessages: [
      {
        validation: (element) => element.value.length !== 0,
        message: t("field-is-required-message", "This field is required."),
      },
    ],
  },
];

setupValidationForForm(accountAcknowledgeFormId, accountAcknowledgeFormValidations);
